
const ua = window.navigator.userAgent
let isMobile = false
console.log('ua',ua)
if (ua.indexOf('iPhone')>= 0 )isMobile = true
if (ua.indexOf('Android') >= 0)isMobile = true;
if (ua.indexOf('iPad') >= 0) isMobile = true;
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  isMobile = true
} else {
  isMobile = false
  // this.$router.push('/morenews')
}
const path = isMobile ? 'mobile/' : '';

import Vue from 'vue'
import VueRouter from 'vue-router'
// import IndexPage from `../views/${path}indexPage.vue`

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'indexpage',
    component: () => import(`../views/${path}indexPage.vue`)
  },
  { 
    path: '/otherpage',
    name: 'otherPage',
    component: () => import(`../views/${path}otherPage.vue`)
  },
  {
    // 视频子页面pc
    path: '/morevideo',
    name: 'morevideo',
    component: () => import('../components/morevideo.vue')
  },
  {
    // 新闻子页面pc
    path: '/morenews',
    name: 'morenews',
    component: () => import('../components/morenews.vue')
  },
  {
    // 新闻子页面
    path: '/mobilemorenews',
    name: 'mobilemorenews',
    component: () => import('../views/mobile/newsPage.vue')
  },
  {
    // 新闻二级内容详情页面
    path: '/mobilenewscontent',
    name: 'mobilenewscontent',
    component: () => import('../components/mobile/newscontent.vue')
  },
  {
    path:'/mobilemorevideo',
    name:'mobilemorevideo',
    component:() => import('@/views/mobile/videoPage.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
